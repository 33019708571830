import ChildLayout from "@/layouts/childLayout.vue";

export default [
    {
        path: "/reply",
        name: "reply",
        component: ChildLayout,
        redirect: "/reply/rule-add",
        meta: {
            title: "自动回复（旧版）",
            icon: "reply_1",
        },
        children: [
            {
                path: "rule-add",
                name: "reply-rule-add",
                component: () => import("@/views/reply/condition-add.vue"),
                meta: {
                    title: "新增策略",
                    icon: "rule-add",
                    activeMenu: "/reply/rule-add",
                },
            },
            {
                path: "rule-edit/:id(\\d+)?",
                name: "reply-rule-edit",
                component: () => import("@/views/reply/condition-add.vue"),
                hidden: true,
                meta: {
                    title: "编辑策略",
                    activeMenu: "/reply/responses",
                },
            },
            {
                path: "responses",
                name: "reply-responses",
                component: () => import("@/views/reply/responses.vue"),
                meta: {
                    title: "全部策略",
                    icon: "responses",
                },
            },
            {
                path: "dict",
                name: "reply-dict",
                component: () => import("@/views/reply/dict.vue"),
                meta: {
                    title: "关键词库",
                    icon: "dict",
                },
            },
            {
                path: "dict-detail/:id(\\d+)",
                name: "reply-dict-detail",
                component: () => import("@/views/reply/dict-detail.vue"),
                hidden: true,
                meta: {
                    title: "关键词详情",
                    activeMenu: "/reply/dict",
                    icon: "dict",
                },
            },
            {
                path: "category",
                name: "reply-category",
                component: () => import("@/views/reply/category.vue"),
                meta: {
                    title: "类型配置",
                    icon: "model",
                },
            },
            {
                path: "history",
                name: "reply-history",
                component: () => import("@/views/reply/history.vue"),
                meta: {
                    title: "历史记录",
                    icon: "log",
                },
            },
            {
                path: "history/:id(\\d+)",
                name: "reply-history-detail",
                component: () => import("@/views/reply/history-detail.vue"),
                hidden: true,
                meta: {
                    title: "提问详情",
                    activeMenu: "/reply/history",
                },
            },
            {
                path: "conversation/:id(\\d+)",
                name: "reply-conversation-detail",
                component: () => import("@/views/reply/conversation-detail.vue"),
                hidden: true,
                meta: {
                    title: "会话详情",
                    activeMenu: "/reply/history",
                },
            },
            {
                path: "history-stat",
                name: "reply-history-stat",
                component: () => import("@/views/reply/history-stat.vue"),
                meta: {
                    title: "统计分析",
                    icon: "stat",
                },
            },
        ],
    },
];
