<template>
    <div class="l-child-default">
        <template v-if="hasPermission">
            <router-view v-slot="{ Component, route }">
                <keep-alive :include="keepAliveList">
                    <component :is="Component" :key="route.path" />
                </keep-alive>
            </router-view>
        </template>
        <template v-else>
            <default-adenoid />
        </template>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import DefaultAdenoid from "@/components/error/default-adenoid.vue";

export default {
    name: "DefaultLayout",
    props: [],
    components: {
        DefaultAdenoid,
    },
    data: function () {
        return {
            // TODO:权限逻辑
            hasPermission: true,

            keepAliveList: [],
        };
    },
    computed: {
        ...mapGetters({
            sidebar: "sidebar",
        }),
        isCollapse() {
            return this.sidebar.opened;
        },
        isNotRoot() {
            return this.$route.name != "__root__";
        },
    },
    watch: {
        $route: {
            deep: true,
            immediate: true,
            handler(to) {
                if (to.meta.keepAlive && this.keepAliveList.indexOf(to.name) == -1) {
                    this.keepAliveList.push(to.name);
                }
            },
        },
    },
    methods: {},
    created: function () {},
    mounted: function () {},
};
</script>

<style lang="less">
@import "@/assets/css/layouts/default.less";
</style>
