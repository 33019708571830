import reply from "./reply";
import Layout from "@/layouts/default.vue";

export default [
    {
        path: "/intelligence",
        name: "intelligence",
        component: Layout,
        redirect: "/intelligence/overview",
        meta: {
            title: "智能小伟",
            icon: "reply",
        },
        children: [
            {
                path: "keywords",
                name: "intelligence-keywords",
                component: () => import("@/views/intelligence/keywords.vue"),
                meta: {
                    title: "关键词",
                    icon: "dict",
                },
            },
            {
                path: "keyword-detail/:id",
                name: "intelligence-keyword-detail",
                component: () => import("@/views/intelligence/keyword-detail.vue"),
                hidden: true,
                meta: {
                    title: "策略详情",
                    icon: "dict",
                    activeMenu: "/intelligence/keywords",
                },
            },
            {
                path: "keyword-edit/:id?",
                name: "intelligence-keyword-edit",
                component: () => import("@/views/intelligence/keyword-edit.vue"),
                hidden: true,
                meta: {
                    title: "策略详情",
                    icon: "dict",
                    activeMenu: "/intelligence/keywords",
                },
            },
            {
                path: "rag",
                name: "intelligence-rag",
                component: () => import("@/views/intelligence/rag.vue"),
                meta: {
                    title: "知识库",
                    icon: "rag",
                },
            },
            {
                path: "rag-detail/:id",
                name: "intelligence-rag-detail",
                component: () => import("@/views/intelligence/rag-detail.vue"),
                hidden: true,
                meta: {
                    title: "知识库详情",
                    icon: "rag",
                    activeMenu: "/intelligence/rag",
                },
            },
            {
                path: "chat",
                name: "intelligence-chat",
                component: () => import("@/views/intelligence/chat.vue"),
                meta: {
                    title: "语料库",
                    icon: "corpus",
                },
            },
            {
                path: "chat-edit/:id?",
                name: "intelligence-chat-edit",
                component: () => import("@/views/intelligence/chat-edit.vue"),
                hidden: true,
                meta: {
                    title: "语料详情",
                    icon: "corpus",
                    activeMenu: "/intelligence/chat",
                },
            },
            {
                path: "history",
                name: "intelligence-history",
                component: () => import("@/views/intelligence/history.vue"),
                meta: {
                    title: "历史会话",
                    icon: "history",
                },
            },
            {
                path: "history-detail/:id",
                name: "intelligence-history-detail",
                component: () => import("@/views/intelligence/history-detail.vue"),
                hidden: true,
                meta: {
                    title: "会话详情",
                    icon: "history",
                    activeMenu: "/intelligence/history/detail",
                },
            },
            {
                path: "category",
                name: "intelligence-category",
                component: () => import("@/views/intelligence/category.vue"),
                meta: {
                    title: "类型配置",
                    icon: "model",
                },
            },
            {
                path: "overview",
                name: "intelligence-overview",
                component: () => import("@/views/intelligence/overview.vue"),
                meta: {
                    title: "统计分析",
                    icon: "stat",
                },
            },
            ...reply,
        ],
    },
];
