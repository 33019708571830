import Layout from "@/layouts/default.vue";

export default [
    {
        path: "/oss",
        name: "oss",
        component: Layout,
        redirect: "/oss/file",
        meta: {
            title: "资源中心",
            icon: "config-file",
        },
        children: [
            {
                path: "file",
                name: "oss-file",
                component: () => import("@/views/oss/file-mng.vue"),
                meta: {
                    title: "AI文件库",
                    icon: "oss",
                },
            },
        ],
    },
];
